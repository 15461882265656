// import store from '@/store/index'
import constants from './constants'
import Vue from '../main'
import * as ArcGIS from './ArcGIS'

let Q = require('q')

// var municipioRequest = null

export const getXYData = async (x, y) => {
  return await getPointInfo(x, y)
}

export const getLatLonData = async (lat, lon) => {
  let [x, y] = await ArcGIS.convertir4326to3857Mercator(lon, lat)
  return await getPointInfo(x, y)
}

async function getPointInfo (x, y) {
  let deferred = Q.defer()

  try {
    let servicioMunicio = constants.servicios.consultaMunicipios
    servicioMunicio = servicioMunicio.replace('/**Point_X**/', x)
    servicioMunicio = servicioMunicio.replace('/**Point_Y**/', y)

    let response = await Vue.$http.get(servicioMunicio)
    let features = response.data.features

    if (features && features[0]) {
      let xyH30 = ArcGIS.convertirETRS89(x, y)
      let latLon = ArcGIS.convertirETRS89ToLatLon(xyH30[0], xyH30[1])
      let lat = latLon[1].toFixed(5)
      let lon = latLon[0].toFixed(5)

      let COD_CCAA = parseInt(features[0].attributes.Cod_CCAA)
      let COD_PROV = parseInt(features[0].attributes.COD_PROV)

      deferred.resolve({
        LATITUD: lat,
        LONGITUD: lon,
        X: Math.round(xyH30[0]),
        Y: Math.round(xyH30[1]),
        MUNICIPIO: features[0].attributes.NOMBRE,
        LOCALIDAD: features[0].attributes.NOMBRE,
        COD_PROV: COD_PROV,
        COD_CCAA: COD_CCAA
      })
    } else {
      deferred.reject()
    }
  } catch (err) {
    deferred.reject(err)
  }

  return deferred.promise
}
