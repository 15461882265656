<template>
  <v-dialog v-model="show" persistent max-width="90%">
    <v-toolbar color="primaryLight" dark dense>
      <v-toolbar-title>Listado de incendios</v-toolbar-title>
    </v-toolbar>
    <div style="height: 80vh;">
      <v-card style="height: 100%;" class="d-flex flex-column">
        <v-card-text class="flex-grow-1 overflow-y-auto d-flex flex-column">
          <v-container class="py-0 ma-0 h-100" fluid>
            <v-row dense class="mt-2 no-spacing-row">
              <v-col cols="11">
                <v-autocomplete
                  v-model="tableFilters"
                  return-object
                  :items="headers.filter(x => x.text !== 'Ocultar/Mostrar')"
                  :items-text="(h) => {return h.text}"
                  item-title="text"
                  label="Mostrar campos"
                  multiple
                  clearable
                  dense
                  outlined
                >
                  <template #selection="{ item, index }">
                    <v-chip v-if="index < maxVisible" :key="item.value" small>
                      {{ item.text }}
                    </v-chip>
                    <span v-else-if="index===maxVisible" :key="index" small>
                      {{ computedHeaders.length - maxVisible - 1 }} más
                    </span>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="1">
                <v-btn
                  icon
                  color="teal"
                  @click="resetFilters()"
                >
                  <v-icon>mdi-cached</v-icon>
                </v-btn>
                <v-btn
                  icon
                  color="teal"
                  @click="mostrarFilAv = !mostrarFilAv,increaseFirstContainerHeight()"
                >
                  <v-icon v-if="!mostrarFilAv">mdi-filter-menu</v-icon>
                  <v-icon v-else>mdi-filter-minus-outline</v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-row dense class="mt-0 no-spacing-row">
              <v-col cols="3">
                <v-text-field label="Nombre" v-model="nombreSelected" clearable outlined dense />
              </v-col>
              <v-col cols="3">
                <v-text-field label="Municipio" v-model="municipioSelected" clearable outlined dense />
              </v-col>
              <v-col cols="2">
                <v-select label="Estado" v-model="estadoSelected" :items="tiposIncendioEstado" item-text="INCENDIO_ESTADO" return-object clearable outlined dense />
              </v-col>
              <v-col cols="2">
                <v-select label="Nivel" v-model="nivelSelected" :items="tiposIncendioNivel" item-text="INCENDIO_NIVEL" return-object clearable outlined dense />
              </v-col>
              <v-col cols="2">
                <v-select label="Mostrar" v-model="ocultosSelected" :items="['Visibles', 'Ocultos']" return-object clearable outlined dense />
              </v-col>
            </v-row>
            <v-row dense class="mt-0 no-spacing-row" v-show="mostrarFilAv">
              <v-col cols="3" class="d-flex flex-row" style="gap: 8px;">
                <v-menu ref="fechaInit" v-model="showFechaInit" :close-on-content-click="false" :nudge-right="40" transition="scale-transition">
                  <template #activator="{ on, attrs }">
                    <v-text-field :value="fechaInitFormatted" @click:clear="fechaInit = null" label="Fecha desde" prepend-inner-icon="mdi-clock-time-four-outline" readonly v-bind="attrs" v-on="on" outlined dense clearable hide-details />
                  </template>
                  <v-date-picker v-model="fechaInit" @input="$refs.fechaInit.save(fechaInit)" locale="es-ES" :max="fechaEnd" />
                </v-menu>

                <v-menu ref="fechaEnd" v-model="showFechaEnd" :close-on-content-click="false" :nudge-right="40" transition="scale-transition">
                  <template #activator="{ on, attrs }">
                    <v-text-field :value="fechaEndFormatted" label="Fecha hasta" @click:clear="fechaEnd = null" prepend-inner-icon="mdi-clock-time-four-outline" readonly v-bind="attrs" v-on="on" outlined dense clearable hide-details />
                  </template>
                  <v-date-picker v-model="fechaEnd" @input="$refs.fechaEnd.save(fechaInit)" locale="es-ES" :min="fechaInit" />
                </v-menu>
              </v-col>

              <v-col cols="3">
                <v-text-field label="Localidad" v-model="localidadIncendioSelected" clearable outlined dense />
              </v-col>

              <v-col cols="2">
                <v-select label="Tipo Detección" v-model="tipoDeteccionSelected" :items="tiposDeteccion" item-text="DETECCION" return-object clearable outlined dense />
              </v-col>
            </v-row>

            <v-row class="no-spacing-row ">
              <v-col>
                <span class="subtitle-1 black--text">Nº de incendios: {{ incendiosFilter.length }}</span>
              </v-col>
            </v-row>
          </v-container>
          <v-container class="py-0 ma-0 flex-grow-1 overflow-y-auto" fluid>
            <div
              class="d-flex flex-column"
              style="max-height: 100%;"
            >
              <v-data-table
                style="overflow: auto !important"

                :headers="computedHeaders"
                :items="incendiosFilter"
                item-key="ID_INCENDIO"
                fixed-header
                hide-default-footer
                disable-pagination
              >
                <template #no-data>
                  <p>Datos no disponibles.</p>
                </template>

                <template #[`item.FECHA_CREACION`]="{ item }">
                  <span>{{ item.FECHA_CREACION | formatDate }}</span>
                </template>

                <template #[`item.ESTADO`]="{ item }">
                  <span>{{ item.ESTADOS[0]?.TIPO_INCENDIO_ESTADO?.INCENDIO_ESTADO || ' ' }}</span>
                </template>

                <template #[`item.TIPO_DETECCION`]="{ item }">
                  <span>{{ getTipoDeteccion(item.ID_TIPO_DETECCION) || ' ' }}</span>
                </template>

                <template #[`item.NIVEL`]="{ item }">
                  <span>{{ getNivel(item.NIVELES) || ' ' }}</span>
                </template>

                <template #[`item.OCULTO`]="{ item }">
                  <v-tooltip bottom>
                    <template #activator="{ on, attrs }">
                      <v-btn icon v-bind="attrs" v-on="on" :disabled="item.ESTADOS[0].TIPO_INCENDIO_ESTADO.VALUE !== 4 && item.ESTADOS[0].TIPO_INCENDIO_ESTADO.VALUE !== 5" @click.stop.prevent="mostrarOcultarIncendio(item)">
                        <v-icon v-show="!item.OCULTO" color="black">mdi-eye-off</v-icon>
                        <v-icon v-show="item.OCULTO" color="black">mdi-eye</v-icon>
                      </v-btn>
                    </template>
                    <span v-show="!item.OCULTO">Ocultar</span>
                    <span v-show="item.OCULTO">Mostrar</span>
                  </v-tooltip>
                </template>
              </v-data-table>
            </div>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn text color="red" @click="cerrar">Cerrar</v-btn>
        </v-card-actions>
      </v-card>
    </div>
  </v-dialog>
</template>

<script>

import { mapGetters } from 'vuex'

export default {
  props: {
    show: Boolean
  },

  data: () => ({
    tableFilters: [],
    mostrarFilAv: false,

    showFechaInit: false,
    showFechaEnd: false,
    fechaInit: null,
    fechaEnd: null,

    nombreSelected: null,
    municipioSelected: null,
    localidadIncendioSelected: null,
    estadoSelected: null,
    tipoDeteccionSelected: null,
    nivelSelected: null,
    ocultosSelected: null,

    headers: [
      { text: 'Nombre', value: 'NOMBRE', align: 'left', sortable: true, initialShow: true },
      { text: 'Municipio', value: 'MUNICIPIO', align: 'left', sortable: true, initialShow: true },
      { text: 'Localidad', value: 'LOCALIDAD', align: 'left', sortable: true, initialShow: true },
      { text: 'Fecha de inicio', value: 'FECHA_CREACION', align: 'left', sortable: true, initialShow: true },
      { text: 'Estado', value: 'ESTADO', align: 'left', sortable: true, initialShow: true },
      { text: 'Tipo Detección', value: 'TIPO_DETECCION', align: 'left', sortable: true, initialShow: true },
      { text: 'Nivel', value: 'NIVEL', align: 'left', sortable: true, initialShow: true },
      { text: 'Ocultar/Mostrar', value: 'OCULTO', align: 'center', sortable: false }
    ],

    incendiosFilter: [],
    maxVisible: 10
  }),

  computed: {
    ...mapGetters('incendio', [
      'tiposIncendioEstado', 'tiposDeteccion', 'tiposIncendioNivel'
    ]),

    incendios () {
      return this.$store.getters['incendio/incendiosAll']
    },

    fechaInitFormatted () {
      let date = this.$date.parseDate(this.fechaInit, 'YYYY-MM-DD')
      return date.isValid() ? this.$date.formatDate(date, 'DD/MM/YYYY') : ''
    },
    fechaEndFormatted () {
      let date = this.$date.parseDate(this.fechaEnd, 'YYYY-MM-DD')
      return date.isValid() ? this.$date.formatDate(date, 'DD/MM/YYYY') : ''
    },
    computedHeaders () {
      return this.headers.filter(x => (this.tableFilters.length > 0 && x.text === 'Ocultar/Mostrar') || this.tableFilters.includes(x))
    }
  },

  watch: {
    show () {
      if (this.show) {
        this.incendiosFilter = this.incendios
        this.filterFechaIncendio()
      }
    },
    incendios () {
      this.filterFechaIncendio()
    },
    nombreSelected () {
      this.filterFechaIncendio()
    },
    fechaInit () {
      this.filterFechaIncendio()
    },
    fechaEnd () {
      this.filterFechaIncendio()
    },
    municipioSelected () {
      this.filterFechaIncendio()
    },
    localidadIncendioSelected () {
      this.filterFechaIncendio()
    },
    estadoSelected () {
      this.filterFechaIncendio()
    },
    tipoDeteccionSelected () {
      this.filterFechaIncendio()
    },
    nivelSelected () {
      this.filterFechaIncendio()
    },
    ocultosSelected () {
      this.filterFechaIncendio()
    }

  },

  methods: {
    filterFechaIncendio () {
      if (this.fechaInit) {
        this.incendiosFilter = this.incendios.filter(x => x.FECHA_CREACION >= this.fechaInit)
      } else {
        this.incendiosFilter = this.incendios
      }

      if (this.fechaEnd) {
        this.incendiosFilter = this.incendiosFilter.filter(x => x.FECHA_CREACION <= this.fechaEnd)
      }

      if (this.nombreSelected) {
        this.incendiosFilter = this.incendiosFilter.filter(x => x.NOMBRE?.toLowerCase().includes(this.nombreSelected.toLowerCase()))
      }

      if (this.municipioSelected) {
        this.incendiosFilter = this.incendiosFilter.filter(x => x.MUNICIPIO.toLowerCase().includes(this.municipioSelected.toLowerCase()))
      }

      if (this.localidadIncendioSelected) {
        this.incendiosFilter = this.incendiosFilter.filter(x => x.LOCALIDAD.toLowerCase().includes(this.localidadIncendioSelected.toLowerCase()))
      }

      if (this.estadoSelected) {
        this.incendiosFilter = this.incendiosFilter.filter(x => x.ESTADOS[0].TIPO_INCENDIO_ESTADO.INCENDIO_ESTADO === this.estadoSelected.INCENDIO_ESTADO)
      }

      if (this.tipoDeteccionSelected) {
        this.incendiosFilter = this.incendiosFilter.filter(x => x.ID_TIPO_DETECCION === this.tipoDeteccionSelected.ID_TIPO_DETECCION)
      }

      if (this.nivelSelected) {
        this.incendiosFilter = this.incendiosFilter.filter(x => x.ID_TIPO_INCENDIO_NIVEL === this.nivelSelected.ID_TIPO_INCENDIO_NIVEL)
      }

      if (this.ocultosSelected) {
        let boolean = this.ocultosSelected === 'Ocultos'
        this.incendiosFilter = this.incendiosFilter.filter(x => x.OCULTO === boolean)
      }
    },

    mostrarOcultarIncendio (incendio) {
      let oculto = !incendio.OCULTO

      this.$store.dispatch('incendio/ocultarIncendio', {
        ID_INCENDIO: incendio.ID_INCENDIO,
        OCULTO: oculto
      })
    },

    getTipoDeteccion (tipoDeteccion) {
      return this.tiposDeteccion.find(tipo => tipo.ID_TIPO_DETECCION === tipoDeteccion)?.DETECCION
    },

    getNivel (niveles) {
      if (!niveles || niveles.length === 0) {
        return null
      }

      return niveles.reduce((latest, current) => {
        const latestFechaInicio = new Date(latest.FECHA_INICIO)
        const currentFechaInicio = new Date(current.FECHA_INICIO)

        return currentFechaInicio > latestFechaInicio ? current : latest
      })?.TIPO_INCENDIO_NIVEL.INCENDIO_NIVEL
    },

    resetFilters () {
      this.tableFilters = this.headers.filter(x => x.initialShow)
    },

    cerrar () {
      this.$emit('cerrar')
    }
  },
  mounted () {
    this.resetFilters()
  }
}
</script>

<style scoped>
  /* Estilos para que la tabla tenga un max-height y pueda crecer */
  .table {
    max-height: 500px;
    overflow: auto;
  }

  .v-data-table >>> .v-data-table__wrapper { /* Header fijo */
    overflow: unset;
  }

  .v-sheet.v-card {
    border-radius: 0;
  }

  .no-spacing-row {
    height: fit-content;
    padding-bottom: 5px;
    margin-top: 0px;
  }

  .no-spacing-row ::v-deep(.v-text-field__details) {
    display: none !important;
  }

  .no-spacing-row ::v-deep(.col) {
    height: fit-content;
  }
</style>
