<template>
  <v-dialog v-model="showDialog" persistent width="400px">
    <v-card>
      <v-toolbar color="primaryLight" dark dense>
        <v-toolbar-title>Añadir incendio</v-toolbar-title>
      </v-toolbar>

      <v-card-text>
        <v-form v-model="isValid">
          <v-container>
            <v-row>
              <v-col>
                <v-text-field v-model="municipio" label="Municipio" placeholder="Municipio" prepend-icon="mdi-terrain" hide-details disabled />
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-text-field v-model="localidad" label="Localidad" placeholder="Localidad" prepend-icon="mdi-terrain" hide-details disabled />
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <vx-date-text-edit v-model="fechaInicio" label="Fecha de inicio" icon="esri-icon-calendar" not-future outlined required />
              </v-col>
            </v-row>

            <v-row>
              <v-col class="d-flex">
                <v-text-field class="pr-2" v-model="latitud" hide-spin-buttons label="Latitud" dense outlined prepend-icon="mdi-map-marker" @blur="updatedLatLon()" type="number" hide-details />
                <v-text-field class="px-2" v-model="longitud" hide-spin-buttons label="Longitud" dense outlined @blur="updatedLatLon()" type="number" hide-details /> <!-- TODO: en alguna ocasion el - no sera obligatorio -->
                <v-tooltip bottom>
                  <template #activator="{ on, attrs }">
                    <v-btn icon color="blue" v-bind="attrs" v-on="on" @click="editLocation">
                      <v-icon>mdi-map-marker-star</v-icon>
                    </v-btn>
                  </template>
                  <span>Editar localización</span>
                </v-tooltip>
              </v-col>
            </v-row>

            <v-row>
              <v-col class="d-flex">
                <v-text-field class="pr-2" v-model="xETRS89" hide-spin-buttons label="X" dense outlined prepend-icon="mdi-map-marker" @blur="updatedXY()" type="number" hide-details />
                <v-text-field class="px-2" v-model="yETRS89" hide-spin-buttons label="Y" dense outlined @blur="updatedXY()" type="number" hide-details />
                <v-tooltip bottom>
                  <template #activator="{ on, attrs }">
                    <v-btn icon color="blue" v-bind="attrs" v-on="on" @click="resetCoords">
                      <v-icon>mdi-refresh</v-icon>
                    </v-btn>
                  </template>
                  <span>Resetar coordenadas</span>
                </v-tooltip>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-select v-model="idTipoIncendio" :items="tiposIncendio" label="Tipo" dense outlined prepend-icon="mdi-fire" :item-text="(tipo) => {return tipo.TIPO_INCENDIO}" item-value="VALUE" placeholder="Tipo incendio" single-line autocomplete hide-details />
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-select v-model="idTipoDeteccion" :items="tiposDeteccion" label="Detección" dense outlined prepend-icon="esri-icon-hollow-eye" item-text="DETECCION" item-value="ID_TIPO_DETECCION" placeholder="Detección" single-line autocomplete hide-details />
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-textarea v-model="observaciones" label="Observaciones" dense outlined placeholder="Observaciones" prepend-icon="esri-icon-comment" auto-grow hide-details />
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn text color="red" @click="cancelar()">Cancelar</v-btn>
        <v-btn text color="green darken-1" :disabled="!isValid" @click="aceptar()">Aceptar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import * as ArcGIS from '../../helpers/ArcGIS'
import * as MunicipioAPI from '../../helpers/MunicipioAPI'

export default {
  props: {
    show: Boolean,
    pointData: Object
  },

  data: () => ({
    showDialog: false,

    isValid: true,

    municipio: null,
    localidad: null,
    codProv: null,
    codCCAA: null,
    latitud: null,
    longitud: null,
    xETRS89: null,
    yETRS89: null,

    fechaInicio: null,
    idTipoDeteccion: 5,
    idTipoIncendio: 0,
    observaciones: null,

    isShowNewCoordenadas: false
  }),

  computed: {
    ...mapGetters('incendio', [
      'tiposDeteccion', 'tiposIncendio'
    ]),

    ...mapGetters('map', [
      'gettingNewCoordenadas',
      'newCoordenadas'
    ])
  },

  watch: {
    show () {
      this.showDialog = this.show

      if (this.show) {
        this.codProv = this.pointData.COD_PROV
        this.codCCAA = this.pointData.COD_CCAA

        this.municipio = this.pointData.MUNICIPIO
        this.localidad = this.pointData.LOCALIDAD

        this.fechaInicio = this.$date.currentDate()

        this.latitud = parseFloat(this.pointData.LATITUD).toFixed(5)
        this.longitud = parseFloat(this.pointData.LONGITUD).toFixed(5)
        this.xETRS89 = this.pointData.X
        this.yETRS89 = this.pointData.Y

        this.paintIncendio()
      }
    },

    gettingNewCoordenadas () {
      if (!this.gettingNewCoordenadas && this.isShowNewCoordenadas && this.show) { // Volver a mostrar el dialogo tras capturar el punto
        this.showDialog = true
      }
    },

    newCoordenadas () {
      if (this.show) {
        this.codProv = this.newCoordenadas.COD_PROV
        this.codCCAA = this.newCoordenadas.COD_CCAA

        this.municipio = this.newCoordenadas.MUNICIPIO
        this.localidad = this.newCoordenadas.LOCALIDAD

        this.latitud = this.newCoordenadas.LATITUD
        this.longitud = this.newCoordenadas.LONGITUD

        this.xETRS89 = this.newCoordenadas.X
        this.yETRS89 = this.newCoordenadas.Y

        this.paintIncendio()
        this.show = true
        this.isShowNewCoordenadas = false
      }
    }
  },

  methods: {
    ...mapActions('map', [
      'startNewCoordenadas', 'setNewCoordenadas'
    ]),

    editLocation () {
      this.showDialog = false
      this.isShowNewCoordenadas = true
      this.startNewCoordenadas()
    },

    clearIncendio () {
      ArcGIS.paintPositionIncendio(null)
    },
    paintIncendio () {
      ArcGIS.paintPositionIncendio({ LATITUD: this.latitud, LONGITUD: this.longitud })
    },

    updatedLatLon () {
      this.updateCoords(parseFloat(this.latitud).toFixed(5), parseFloat(this.longitud).toFixed(5), 'Las coordenadas Lat/Lon no son válidas')
    },

    updatedXY () {
      try {
        let [LONGITUD, LATITUD] = ArcGIS.convertirETRS89ToLatLon(this.xETRS89, this.yETRS89)
        this.updateCoords(LATITUD.toFixed(5), LONGITUD.toFixed(5), 'Las coordenadas X/Y no son válidas')
      } catch (err) {
        this.handleUpdateError('Las coordenadas X/Y no son válidas')
      }
    },

    async updateCoords (lat, lon, errorMessage) {
      try {
        let pointData = await MunicipioAPI.getLatLonData(lat, lon)
        this.setNewCoordenadas(pointData)
        this.isValid = true
      } catch (err) {
        this.handleUpdateError(errorMessage)
      }
    },

    handleUpdateError (message) {
      this.isValid = false
      this.$notify({
        title: 'Datos incendio',
        text: message,
        type: 'error'
      })
    },

    resetCoords () {
      this.latitud = this.pointData.LATITUD
      this.longitud = this.pointData.LONGITUD
      this.xETRS89 = this.pointData.X
      this.yETRS89 = this.pointData.Y
      this.isValid = true
      this.paintIncendio()
    },

    cancelar () {
      this.clearIncendio()
      this.$emit('cancelar')

      this.idTipoDeteccion = 5
      this.observaciones = null
    },

    aceptar () {
      let incendio = {}
      let tipoIncendioEstado = this.$store.getters['incendio/getTipoIncendioEstadoByValue'](0)

      this.clearIncendio()

      let fechaCreacion = this.$date.parseDate(this.fechaInicio)

      incendio.ID_INCENDIO = this.$uuid.createUUID()
      incendio.ID_TIPO_DETECCION = this.idTipoDeteccion
      incendio.ID_TIPO_INCENDIO = this.tiposIncendio.filter(tipo => tipo.VALUE === this.idTipoIncendio).map(tipo => tipo.ID_TIPO_INCENDIO)[0]
      incendio.MUNICIPIO = this.municipio
      incendio.LOCALIDAD = this.localidad
      incendio.COD_PROV = this.codProv
      incendio.COD_CCAA = this.codCCAA
      incendio.LATITUD = this.latitud
      incendio.LONGITUD = this.longitud
      incendio.OBSERVACIONES = this.observaciones
      incendio.FECHA_CREACION = fechaCreacion
      incendio.ESTADOS = [
        {
          ID_INCENDIO_ESTADO: this.$uuid.createUUID(),
          ID_INCENDIO: incendio.ID_INCENDIO,
          ID_TIPO_INCENDIO_ESTADO: tipoIncendioEstado.ID_TIPO_INCENDIO_ESTADO,
          FECHA: fechaCreacion,
          TIPO_INCENDIO_ESTADO: tipoIncendioEstado
        }
      ]
      incendio.NIVELES = []
      incendio.SECTORES = [
        {
          ID_SECTOR: this.$uuid.createUUID(),
          ID_INCENDIO: incendio.ID_INCENDIO,
          SECTOR: 'GENERAL',
          MEDIOS: []
        }
      ]

      this.$emit('aceptar', incendio)
      this.idTipoDeteccion = 5
      this.observaciones = null
    }
  }
}
</script>

<style scoped>
</style>
