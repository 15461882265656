<template>
  <v-dialog v-model="showDialog" persistent max-width="400">
    <v-card>
      <v-toolbar color="primaryLight" dark dense>
        <v-toolbar-title>{{ editIncendio.LOCALIDAD }}</v-toolbar-title>
      </v-toolbar>

      <v-card-text>
        <v-form v-model="isValid">
          <v-container fluid>
            <v-row>
              <v-col>
                <v-text-field v-model="editIncendio.NOMBRE" label="Nombre" placeholder="Nombre" prepend-icon="mdi-tag" hide-details />
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-text-field v-model="editIncendio.MUNICIPIO" label="Municipio" placeholder="Municipio" prepend-icon="mdi-terrain" hide-details disabled />
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-text-field v-model="editIncendio.LOCALIDAD" label="Localidad" placeholder="Localidad" prepend-icon="mdi-terrain" hide-details disabled />
              </v-col>
            </v-row>

            <v-row>
              <v-col class="d-flex">
                <v-text-field class="pr-2" v-model="editIncendio.LATITUD" hide-spin-buttons label="Latitud" dense outlined prepend-icon="mdi-map-marker" @blur="updatedLatLon()" @focus="coordsFocused()" type="number" hide-details />
                <v-text-field class="px-2" v-model="editIncendio.LONGITUD" hide-spin-buttons label="Longitud" dense outlined @blur="updatedLatLon()" @focus="coordsFocused()" type="number" hide-details /> <!-- TODO: en alguna ocasion el - no sera obligatorio -->
                <v-tooltip bottom>
                  <template #activator="{ on, attrs }">
                    <v-btn icon color="blue" v-bind="attrs" v-on="on" @click="editLocation">
                      <v-icon>mdi-map-marker-star</v-icon>
                    </v-btn>
                  </template>
                  <span>Editar localización</span>
                </v-tooltip>
              </v-col>
            </v-row>

            <v-row>
              <v-col class="d-flex justify-space-between">
                <v-text-field class="pr-2" v-model="xETRS89" hide-spin-buttons label="X" dense outlined prepend-icon="mdi-map-marker" @blur="updatedXY()" @focus="coordsFocused()" type="number" hide-details />
                <v-text-field class="px-2" v-model="yETRS89" hide-spin-buttons label="Y" dense outlined @blur="updatedXY()" @focus="coordsFocused()" type="number" hide-details />
                <v-tooltip bottom>
                  <template #activator="{ on, attrs }">
                    <v-btn icon color="blue" v-bind="attrs" v-on="on" @click="resetCoords">
                      <v-icon>mdi-refresh</v-icon>
                    </v-btn>
                  </template>
                  <span>Resetar coordenadas</span>
                </v-tooltip>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-select v-model="editIncendio.ID_TIPO_INCENDIO" :items="tiposIncendio" label="Tipo" dense outlined prepend-icon="mdi-fire" :item-text="(tipo) => {return tipo.TIPO_INCENDIO}" item-value="ID_TIPO_INCENDIO" placeholder="Tipo incendio" single-line autocomplete hide-details :disabled="disabledTipoIncendio" />
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-select v-model="editIncendio.ID_TIPO_DETECCION" :items="tiposDeteccion" label="Detección" dense outlined prepend-icon="esri-icon-hollow-eye" item-text="DETECCION" item-value="ID_TIPO_DETECCION" placeholder="Detección" single-line autocomplete hide-details />
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-textarea v-model="editIncendio.OBSERVACIONES" label="Observaciones" dense outlined placeholder="Observaciones" prepend-icon="esri-icon-comment" auto-grow hide-details :rules="[v => (v || '' ).length <= 100 || 'Número de caracteres maximo: 100']" />
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn text color="red" @click="cancelar">Cancelar</v-btn>
        <v-btn text color="green darken-1" @click="aceptar" :disabled="!isValid">Aceptar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import * as ArcGIS from '../../../helpers/ArcGIS'
import * as MunicipioAPI from '../../../helpers/MunicipioAPI'

export default {
  props: {
    show: Boolean,
    incendio: Object
  },

  data: () => ({
    showDialog: false,
    isValid: true,
    editIncendio: {},
    isShowNewCoordenadas: false,
    xETRS89: 0,
    yETRS89: 0,
    coordFocus: false,
    actionWait: ''
  }),

  computed: {
    ...mapGetters('incendio', [
      'tiposDeteccion', 'tiposIncendio'
    ]),

    ...mapGetters('map', [
      'gettingNewCoordenadas',
      'newCoordenadas'
    ]),

    disabledTipoIncendio () {
      if (this.incendio && this.incendio.ESTADOS) {
        return this.incendio.ESTADOS.some(estado => {
          return (this.incendio.ID_TIPO_INCENDIO === this.tiposIncendio[0].ID_TIPO_INCENDIO && estado.TIPO_INCENDIO_ESTADO.VALUE === 2) ||
          (this.incendio.ID_TIPO_INCENDIO === this.tiposIncendio[1].ID_TIPO_INCENDIO && estado.TIPO_INCENDIO_ESTADO.VALUE === 4)
        })
      }
      return false
    }
  },

  watch: {
    show () {
      this.showDialog = this.show

      if (this.show) {
        this.editIncendio = JSON.parse(JSON.stringify(this.incendio))
        let coor3857 = ArcGIS.convertirLatLonToETRS89(this.editIncendio.LATITUD, this.editIncendio.LONGITUD)

        this.xETRS89 = Math.round(coor3857[0])
        this.yETRS89 = Math.round(coor3857[1])
      }
    },

    gettingNewCoordenadas () {
      if (!this.gettingNewCoordenadas && this.isShowNewCoordenadas && this.show) { // Volver a mostrar el dialogo tras capturar el punto
        this.showDialog = true
      }
    },

    newCoordenadas () {
      if (this.isShowNewCoordenadas) {
        this.showDialog = true
        this.isShowNewCoordenadas = false
      }

      this.editIncendio.MUNICIPIO = this.newCoordenadas.MUNICIPIO
      this.editIncendio.LOCALIDAD = this.newCoordenadas.LOCALIDAD

      this.editIncendio.LATITUD = this.newCoordenadas.LATITUD
      this.editIncendio.LONGITUD = this.newCoordenadas.LONGITUD

      this.xETRS89 = this.newCoordenadas.X
      this.yETRS89 = this.newCoordenadas.Y

      this.paintIncendio()
    },
    coordFocus (val) {
      if (this.actionWait && this.actionWait !== '' && !val) {
        let action = this.actionWait
        this.actionWait = ''
        this.executeAction(action)
      }
    }
  },

  methods: {
    ...mapActions('map', [
      'startNewCoordenadas', 'setNewCoordenadas'
    ]),

    clearIncendio () {
      ArcGIS.paintPositionIncendio(null)
    },
    paintIncendio () {
      // Por aqui no pasa
      ArcGIS.paintPositionIncendio({ LATITUD: this.editIncendio.LATITUD, LONGITUD: this.editIncendio.LONGITUD })
    },

    editLocation () {
      this.showDialog = false
      this.isShowNewCoordenadas = true
      this.startNewCoordenadas()
    },

    coordsFocused () {
      this.coordFocus = true
    },

    async updatedLatLon () {
      let { LATITUD, LONGITUD } = this.editIncendio
      this.updateCoords(parseFloat(LATITUD).toFixed(5), parseFloat(LONGITUD).toFixed(5), 'Las coordenadas Lat/Lon no son válidas')
    },

    async updatedXY () {
      try {
        let [LONGITUD, LATITUD] = ArcGIS.convertirETRS89ToLatLon(this.xETRS89, this.yETRS89)
        this.updateCoords(LATITUD.toFixed(5), LONGITUD.toFixed(5), 'Las coordenadas X/Y no son válidas')
      } catch (err) {
        this.handleUpdateError('Las coordenadas X/Y no son válidas')
      }
    },

    async updateCoords (lat, lon, errorMessage) {
      try {
        let pointData = await MunicipioAPI.getLatLonData(lat, lon)
        this.setNewCoordenadas(pointData)
        this.coordFocus = false
        this.isValid = true
      } catch (err) {
        this.handleUpdateError(errorMessage)
      }
    },

    handleUpdateError (message) {
      this.isValid = false
      this.coordFocus = false
      this.$notify({
        title: 'Datos incendio',
        text: message,
        type: 'error'
      })
    },

    resetCoords () {
      const incendio = JSON.parse(JSON.stringify(this.incendio))
      let coor3857 = ArcGIS.convertirLatLonToETRS89(incendio.LATITUD, incendio.LONGITUD)

      this.xETRS89 = Math.round(coor3857[0])
      this.yETRS89 = Math.round(coor3857[1])

      this.editIncendio.LATITUD = parseFloat(incendio.LATITUD).toFixed(5)
      this.editIncendio.LONGITUD = parseFloat(incendio.LONGITUD).toFixed(5)
      this.isValid = true
      this.clearIncendio()
    },

    cancelar () {
      this.executeAction('cancelar')
    },
    aceptar () {
      this.executeAction('aceptar')
    },
    executeAction (action) {
      // Si los v-text-field de coordenadas no estan seleccionados ejecutamos la accion, si alguno lo esta, esperamos a que se ejecute su evento @blur
      if (!this.coordFocus && !this.actionWait) {
        this.clearIncendio()
        this.$emit(action, this.editIncendio)
      } else {
        this.actionWait = action
      }
    }
  }
}
</script>

<style scoped>
</style>
