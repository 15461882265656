<template>
  <v-footer id="footerMap" class="vexiza-primary-dark" dark v-resize="onResize">
    <span class="px-4">
      <span class="footerTitle">Escala: &nbsp;</span> 1:{{ escala }}
    </span>

    <span class="px-4" v-if="footerSize >= 525">
      <span class="footerTitle pr-1">UTM ETRS89 H{{ HUSO_UTM }}N X:</span> {{ coordenadas.X_ETRS89 }}
      <span class="footerTitle pl-2 pr-1">Y:</span> {{ coordenadas.Y_ETRS89 }}
    </span>

    <span class="px-4" v-if="footerSize > 950">
      <span class="footerTitle pr-1">WGS84 GD Lat:</span> {{ coordenadas.LATITUDE }}
      <span class="footerTitle pr-1">Lon:</span> {{ coordenadas.LONGITUDE }}
      <span class="footerTitle pr-1" v-if="footerSize > 1050">| GMS Lat:</span> {{ coordenadas.LATITUDE_GMS }}
      <span class="footerTitle pr-1" v-if="footerSize > 1050">Lon:</span> {{ coordenadas.LONGITUDE_GMS }}
    </span>

    <span class="px-4" v-if="footerSize > 1350">
      <span v-show="municipio != null" class="footerTitle pr-1">Municipio:</span> {{ municipio }}
    </span>

    <v-spacer />
    <a href="http://vexiza.com" class="pr-1" style="display: flex; color: white">
      <img src="../../assets/logo_vexiza_blanco.svg" alt="Vexiza" style="height: 26px">
    </a>
  </v-footer>
</template>

<script>
import constants from '../../helpers/constants'
// import * as MunicipioAPI from '../../helpers/MunicipioAPI'

export default {
  data: () => ({
    HUSO_UTM: '',

    escala: null,
    coordenadas: {},
    municipio: null,

    footerSize: 0 // Ancho del footer para ocultar info
  }),
  methods: {
    onResize () {
      let vista = document.getElementById('footerMap')
      if (vista && vista !== null) {
        this.footerSize = vista.offsetWidth
      }
    },

    setNewEscalaMap (escala) {
      this.escala = Math.floor(escala).toLocaleString('es-ES')
    },

    async setNewCoordenadasMap (coordenadas) {
      this.coordenadas = coordenadas

      // let pointData = await MunicipioAPI.getXYData(coordenadas.X, coordenadas.Y) // TODO: Esto da lag si se hace muy a menudo
      // this.municipio = pointData.MUNICIPIO
    }
  },
  mounted () {
    this.$eventHub.$on('coordenadasMap', this.setNewCoordenadasMap)
    this.$eventHub.$on('escalaMap', this.setNewEscalaMap)
    this.HUSO_UTM = constants.HUSO_UTM
  }
}
</script>

<style scoped>
  .v-footer {
    padding: 0px;
    min-height: 0px !important; /* Por algun motivo vuetify le pone un minimo de 36px */
  }

  #footerMap {
    height: 25px !important; /* Por algun motivo vuetify le pone 32px */
    font-size: 12px;
  }

  .footerTitle {
    color: #fafafa8a;
  }
</style>
