import { render, staticRenderFns } from "./AllInfoDialog.vue?vue&type=template&id=516067b5&scoped=true&"
import script from "./AllInfoDialog.vue?vue&type=script&lang=js&"
export * from "./AllInfoDialog.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "516067b5",
  null
  
)

export default component.exports